import axios from 'axios';
import { computed, onUnmounted, shallowRef, triggerRef, watch } from 'vue-demi';
import useBackOff from '@/utils/backOff/useBackOff';
import assertHasScope from '@/utils/other/assertHasScope';
import { useAxios } from './useAxios';
import { useRealTimeUpdates } from './useRealTimeUpdates';

/**
 * Loads a single item from the Teamwork API.
 * Supports retrying with customizable delay and real-time updates.
 */
export default function useItemLoader({
  /**
   * The request url.
   */
  url: _url,
  /**
   * The request params.
   */
  params: _params,
  /**
   * Gets an item from the server response.
   */
  responseToItem: _responseToItem = () => undefined,
  /**
   * Gets metadata from the server response.
   */
  responseToMeta: _responseToMeta = () => null,
}) {
  assertHasScope();
  const axiosInstance = useAxios();
  const { handlingEventFromSocket } = useRealTimeUpdates();
  const url = shallowRef(_url);
  const params = shallowRef(_params);
  const responseToItem = shallowRef(_responseToItem);
  const responseToMeta = shallowRef(_responseToMeta);
  const backOff = useBackOff();
  const loadedItem = shallowRef(undefined);
  const needsRefresh = shallowRef(true);
  const response = shallowRef(undefined);
  const error = shallowRef(undefined);
  const meta = shallowRef(undefined);
  const cancel = shallowRef(undefined);
  const loading = computed(() => Boolean(cancel.value));
  const optimisticUpdates = shallowRef(new Set());
  const item = computed(() => {
    let processedItem = loadedItem.value;
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      processedItem = optimisticUpdate.apply(processedItem);
    });
    return processedItem;
  });
  const inSync = computed(() => !needsRefresh.value && optimisticUpdates.value.size === 0);
  // We force using the browser cache for the initial request in order to show the cached data immediately.
  // After that request completes, we immediately call `refresh` to load fresh data from the server.
  const shouldUseCache = computed(() => response.value === undefined && error.value === undefined);
  let triggeredBy = 'user';

  function refresh() {
    needsRefresh.value = true;
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      if (optimisticUpdate.updated) {
        clearTimeout(optimisticUpdate.timeout);
        // eslint-disable-next-line no-param-reassign
        optimisticUpdate.refreshing = true;
      }
    });
    triggeredBy = handlingEventFromSocket.value ? 'event/ws' : 'event/local';
  }

  function reset() {
    loadedItem.value = undefined;
    response.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    refresh();
    triggeredBy = 'user';
  }

  function update(apply, promise) {
    const optimisticUpdate = { apply, promise };
    promise.then(
      () => {
        if (needsRefresh.value) {
          // Keep the update until the data is refreshed.
          optimisticUpdate.promise = undefined;
        } else {
          // Discard the update, as it did not affect this loader.
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      },
      () => {
        // Discard the update, as it failed.
        optimisticUpdates.value.delete(optimisticUpdate);
        triggerRef(optimisticUpdates);
      },
    );
    // Apply the update optimistically.
    optimisticUpdates.value.add(optimisticUpdate);
    triggerRef(optimisticUpdates);
  }

  // Prunes optimistic updates which have been saved and read back from the server.
  function prune() {
    if (!needsRefresh.value) {
      optimisticUpdates.value.forEach((optimisticUpdate) => {
        if (!optimisticUpdate.promise) {
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      });
    }
  }

  async function load() {
    // loading in progress
    if (cancel.value) {
      return;
    }

    // back-off active
    if (backOff.active.value) {
      return;
    }

    // already in sync with the server
    if (!needsRefresh.value) {
      return;
    }

    if (typeof url.value !== 'string') {
      needsRefresh.value = false;
      return; // invalid url
    }

    try {
      response.value = await new Promise((resolve, reject) => {
        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new axios.Cancel());
        axiosInstance
          .get(url.value, {
            params: params.value,
            headers: {
              'Triggered-By': triggeredBy,
              'Sent-By': 'composable',
              Accept: 'application/json',
            },
            // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
            cache: shouldUseCache.value ? 'only-if-cached' : undefined,
            mode: shouldUseCache.value ? 'same-origin' : undefined,
          })
          .then(resolve, reject);
      });

      error.value = undefined;

      loadedItem.value = responseToItem.value(response.value);
      meta.value = responseToMeta.value(response.value);
      needsRefresh.value = false;

      backOff.reset();
    } catch (axiosError) {
      if (axios.isCancel(axiosError)) {
        return;
      }

      if (!axiosError.config || axiosError.config.cache !== 'only-if-cached') {
        console.error('Error in useItemLoader:', axiosError);
      }

      response.value = undefined;
      error.value = axiosError;

      if (error.value.response && error.value.response.status === 404) {
        backOff.reset();
        loadedItem.value = null;
        needsRefresh.value = false;
      } else {
        backOff.start();
      }
    } finally {
      cancel.value = undefined;
    }
  }

  watch(url, reset);
  watch(params, reset, { deep: true });
  watch(responseToItem, reset);
  watch(responseToMeta, reset);

  watch(cancel, load);
  watch(backOff.active, load);
  watch(needsRefresh, load);

  watch(needsRefresh, prune);
  watch(shouldUseCache, () => {
    if (!shouldUseCache.value) {
      refresh();
      triggeredBy = 'user';
    }
  });

  onUnmounted(reset);
  load();

  return {
    state: {
      /**
       * The loaded item, if loaded and exists,
       * `null`, if loaded and does not exist,
       * or `undefined`, if not loaded yet.
       */
      item,
      /**
       * Indicates if the item is in sync with the server.
       */
      inSync,
      /**
       * Indicates if item is being loaded.
       */
      loading,
      /**
       * The loaded metadata.
       */
      meta,
      /**
       * The response produced by the last axios request.
       */
      response,
      /**
       * The error produced by the last axios request.
       */
      error,
    },
    /**
     * Refreshes the item by reloading it from the server.
     */
    refresh,
    /**
     * Updates the item locally, while waiting for the same change to be saved on the server.
     * @param apply(Item | null | undefined): Item | null | undefined
     *   Gets an item and returns its new version with modifications.
     *   It MUST NOT modify the original item.
     * @param promise A Promise tracking the request which makes the corresponding change on the server.
     */
    update,
  };
}
