import moment from 'moment';
import { v1Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const { hasOwnProperty } = Object.prototype;

const newTaskId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

function createToDoItem(task) {
  const toDoItem = {
    id: task.id,
  };
  if (hasOwnProperty.call(task, 'name')) {
    toDoItem.content = task.name;
  }
  if (hasOwnProperty.call(task, 'startDate')) {
    toDoItem['start-date'] =
      moment.isMoment(task.startDate) && task.startDate.isValid() ? task.startDate.format('YYYYMMDD') : '';
  }
  if (hasOwnProperty.call(task, 'dueDate')) {
    toDoItem['due-date'] =
      moment.isMoment(task.dueDate) && task.dueDate.isValid() ? task.dueDate.format('YYYYMMDD') : '';
  }
  if (hasOwnProperty.call(task, 'originalDueDate')) {
    toDoItem['original-due-date'] =
      moment.isMoment(task.originalDueDate) && task.originalDueDate.isValid()
        ? task.originalDueDate.format('YYYYMMDD')
        : '';
  }
  if (hasOwnProperty.call(task, 'pushSubtasks')) {
    toDoItem['push-subtasks'] = task.pushSubtasks;
  }
  if (hasOwnProperty.call(task, 'pushDependents')) {
    toDoItem['push-dependents'] = task.pushDependents;
  }
  if (hasOwnProperty.call(task, 'notify')) {
    toDoItem.notify = task.notify;
  }
  if (hasOwnProperty.call(task, 'progress')) {
    toDoItem.progress = task.progress;
  }
  if (hasOwnProperty.call(task, 'numEstMins')) {
    toDoItem['estimated-minutes'] = task.numEstMins;
  }
  if (hasOwnProperty.call(task, 'priority')) {
    toDoItem.priority = task.priority;
  }
  if (hasOwnProperty.call(task, 'description')) {
    toDoItem.description = task.description;
  }
  if (hasOwnProperty.call(task, 'boardColumn')) {
    toDoItem.columnId = task.boardColumn ? task.boardColumn.id : 0;
  }
  if (hasOwnProperty.call(task, 'customFields')) {
    toDoItem.customFields = task.customFields;
  }
  if (hasOwnProperty.call(task, 'tags')) {
    toDoItem.tagIds = task.tags.map((tag) => tag.id).join(',');
  }
  if (hasOwnProperty.call(task, 'taskListId')) {
    toDoItem.taskListId = task.taskListId;
  }
  if (hasOwnProperty.call(task, 'positionAfterTaskId')) {
    toDoItem.positionAfterTask = task.positionAfterTaskId;
  }
  if (
    hasOwnProperty.call(task, 'assignedTo') ||
    hasOwnProperty.call(task, 'assignedToCompanies') ||
    hasOwnProperty.call(task, 'assignedToTeams')
  ) {
    toDoItem['responsible-party-id'] = [
      ...(task.assignedTo || []).map((m) => m.id),
      ...(task.assignedToCompanies || []).map((m) => `c${m.companyId || m.id}`),
      ...(task.assignedToTeams || []).map((m) => `t${m.teamId || m.id}`),
    ].join(',');
  }
  if (hasOwnProperty.call(task, 'parentTaskId')) {
    toDoItem.parentTaskId = task.parentTaskId;
  }
  if (hasOwnProperty.call(task, 'changeFollowers')) {
    toDoItem.changeFollowerIds = task.changeFollowers.map((followers) => followers.id).join(',');
  }
  if (hasOwnProperty.call(task, 'commentFollowers')) {
    toDoItem.commentFollowerIds = task.commentFollowers.map((followers) => followers.id).join(',');
  }
  if (hasOwnProperty.call(task, 'repeatOptions')) {
    toDoItem.repeatOptions = task.repeatOptions;
  }
  if (hasOwnProperty.call(task, 'predecessors')) {
    toDoItem.predecessors = task.predecessors;
  }
  return toDoItem;
}

export default function useTaskActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function emitOptimisticUpdate(promise, action, task) {
    const {
      // We remove tasklist and project from the task included in optimistic updates
      // to keep it simple and avoid potential inconsistency between
      // `tasklistId` / `tasklist.id`  and `projectId` / `project.id`.
      tasklist,
      project,
      // TODO Remove `taskList` when no longer used.
      taskList,
      // TODO Remove `taskListId` when no longer used.
      taskListId,
      ...normalizedTask
    } = task;
    // TODO Remove taskListId when no longer used.
    normalizedTask.tasklistId ??= taskListId;
    // TODO Remove taskListId when no longer used.
    normalizedTask.taskListId = normalizedTask.tasklistId;
    _emitOptimisticUpdate({
      promise,
      type: 'task',
      action,
      task: normalizedTask,
    });
  }

  function emitRealTimeUpdate(action, newTask, oldTask) {
    const taskId = newTask.id ?? oldTask.id;
    _emitRealTimeUpdate({
      type: 'task',
      action,
      taskId,
      parentTaskId: newTask.parentTaskId ?? oldTask.parentTaskId,
      previousParentTaskId: oldTask.parentTaskId,
      taskListId: newTask.taskListId ?? oldTask.taskListId,
      previousTaskListId: oldTask.taskListId,
      projectId: newTask.projectId ?? oldTask.projectId,
      previousProjectId: oldTask.projectId,
      hasDependents: Boolean(newTask?.dependencyIds),
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    createTask(newTask) {
      const promise = api
        .post(
          v1Url(`tasklists/${newTask.taskListId}/tasks`),
          {
            'todo-item': createToDoItem(newTask),
          },
          config(),
        )
        .then(({ data: { id } }) => emitRealTimeUpdate('new', { ...newTask, id: Number(id) }, {}));
      emitOptimisticUpdate(promise, 'create', { ...newTask, id: newTaskId() });
      return promise;
    },

    updateTask(updatedTask, task = {}, options) {
      const promise = api
        .put(
          v1Url(`tasks/${updatedTask.id}`),
          {
            ...options,
            'todo-item': createToDoItem(updatedTask),
          },
          config(),
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });
      return promise;
    },

    deleteTask(task) {
      const promise = api
        .delete(v1Url(`tasks/${task.id}`), config())
        .then(() => emitRealTimeUpdate('deleted', task, task));
      emitOptimisticUpdate(promise, 'delete', task);
      return promise;
    },

    completeTask(task) {
      const promise = api
        .put(v1Url(`tasks/${task.id}/complete`), null, config())
        .then(() => emitRealTimeUpdate('completed', task, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        progress: 100,
        status: 'completed',
      });
      return promise;
    },

    uncompleteTask(task) {
      const promise = api
        .put(v1Url(`tasks/${task.id}/uncomplete`), null, config())
        .then(() => emitRealTimeUpdate('reopened', task, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        progress: 0,
        status: 'reopened',
      });
      return promise;
    },

    /**
     * A specialization of `updateTask` which moves a task only and does not update any other proprties.
     * It provides better UX using optimistic updates when dragging tasks.
     *
     * For optimal UX provide a whole new task object as `updatedTask` and a whole old task object as `task`.
     */
    moveTask(updatedTask, task = {}) {
      const { id, parentTaskId, taskListId, projectId, positionAfterTaskId } = updatedTask;
      const promise = api
        .put(
          v1Url(`tasks/${id}`),
          {
            'todo-item': createToDoItem({
              id,
              parentTaskId,
              taskListId,
              projectId,
              positionAfterTaskId,
            }),
          },
          config(),
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
        previousParentTaskId: task.parentTaskId,
        previousTaskListId: task.taskListId,
        previousProjectId: task.projectId,
      });
      return promise;
    },
    /*
     * Very similar way to `updateTask` but only updates progress property.
     * We use this because `/task` endpoint doesn't support updating progress when the user is collaborator
     */
    updateTaskProgress(updatedTask, task = {}) {
      const promise = api
        .put(
          v1Url(`tasks/${updatedTask.id}/progress`),
          {
            progress: updatedTask.progress,
          },
          config(),
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', {
        ...task,
        ...updatedTask,
      });
      return promise;
    },
    /**
     * Updates the stage that a task is on
     *
     */
    changeTaskWorkflowStage(updatedWorkflowStage, task) {
      if (!task) {
        return Promise.reject(new Error('useTaskActions: cannot find the task to update'));
      }
      const { stageId, workflowId } = updatedWorkflowStage;
      const updatedTask = {
        ...task,
        workflowStages: task.workflowStages.map((workflowStage) =>
          workflowStage.workflowId === workflowId ? { ...workflowStage, ...updatedWorkflowStage } : workflowStage,
        ),
      };
      const promise = api
        .patch(
          `/projects/api/v3/tasks/${updatedTask.id}/workflows/${workflowId}.json`,
          {
            workflowId,
            stageId,
            positionAfterTask: 0, // Add at the bottom
          },
          {
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => emitRealTimeUpdate('edited', updatedTask, task));
      emitOptimisticUpdate(promise, 'update', updatedTask);

      return promise;
    },
  };
}
