import { createBatchLoader } from '@/store/utils/loader/batch';
import { API } from '@/store/utils/loader';

export default createBatchLoader({
  namespaced: true,
  config: {
    url: `${API.v2}/tasks.json`,
    param: 'taskIdList',
    module: 'task',
    mapToIncluded: (ctx, { data: { tasks } }) => ({
      boardColumns: tasks.map(({ boardColumn }) => boardColumn).filter(Boolean),
      tags: [].concat(...tasks.map(({ tags }) => tags).filter(Boolean)),
    }),
    includedConfig: { boardColumns: 'boardColumn', tags: 'tag' },
  },
  actions: {
    // Iteratively retrieves the parent tasks of the batch if they don't exist
    async accessWithParents({ dispatch, rootState: { task } }, batch) {
      let currBatch = batch;
      while (currBatch && currBatch.length) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch('access', currBatch);
        currBatch = [
          ...new Set(currBatch.map((tid) => task.records[tid] && task.records[tid].parentTaskId).filter(Boolean)),
        ];
      }
    },
  },
});
