<script>
import { computed, watch, onMounted } from 'vue-demi';
import { MountingPortal } from 'portal-vue';
import { useLocalStorage } from '@vueuse/core';
import TkoComponent from '@sections/TkoComponent';
import HybridModals from '@sections/HybridModals';
import HybridQuickViews from '@sections/HybridQuickViews';
import WindowEvents from '@widgets/WindowEvents';
import HybridPopoverContainer from '@sections/HybridPopoverContainer';
import { provideFilters } from '@sections/GenericFilter/composables/useFilters';
import { useCurrentUser, useBranding, useCurrentAccount } from '@/api';

import buildThemeCSS from '@/utils/helpers/themeCSS';
import useStore from '@/platform/composables/useStore';
import { useHybrid } from '@/platform/composables/useHybrid';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

import { useAppShell } from '@/platform/composables/useAppShell';
import { inboxBaseFilterSymbol, provideInboxFilter } from '@/views/inbox/composables/useInboxFilter';
import { provideInboxUnreadCount } from '@/views/inbox/composables/useInboxUnreadCount';

import usePendo from '@/platform/composables/usePendo';

// @vue/component
export default {
  name: 'App',
  components: {
    WindowEvents,
    TkoComponent,
    HybridModals,
    HybridQuickViews,
    HybridPopoverContainer,
    MountingPortal,
    ProfilePopoverSingleton: () =>
      import(/* webpackChunkName: "profile-popover-singleton" */ '@sections/ProfilePopoverSingleton'),
  },
  setup() {
    const user = useCurrentUser();
    const branding = useBranding();
    const store = useStore();
    const { isHybridReady } = useHybrid();
    const { redirectTo, lightspeedState } = useLightspeedBridge();

    const { shouldUseAppShell } = useAppShell();

    const account = useCurrentAccount();

    // Set branding details in localStorage.
    useLocalStorage('teamwork/branding', branding.value);

    // we must initialise inbox filter at root of app because it
    // is shared by the inbox unread count and the inbox ui
    const inboxFilter = provideInboxFilter();
    provideFilters(
      {
        filter: inboxFilter,
        hasSavedFilters: false,
      },
      inboxBaseFilterSymbol,
    );

    const { fetch: fetchInboxUnreadCount } = provideInboxUnreadCount();
    fetchInboxUnreadCount();

    onMounted(() => {
      const { trackPendoEvent } = usePendo();

      trackPendoEvent({ eventName: 'Retainer_Feature_Enabled' });
    });

    watch(
      store.state.user,
      (userStore) => {
        if (userStore.status === 'unauthenticated') {
          setTimeout(() => {
            console.info('App.vue watcher - redirecting to login');
            redirectTo(`/launchpad/login/projects?continue=${encodeURIComponent(lightspeedState.value.parentHref)}`);
          }, 500);
        } else if (userStore.status === 'logged-out') {
          redirectTo('/launchpad/logout/projects');
        }
      },
      { immediate: true },
    );

    return {
      isHybridReady,
      userId: computed(() => user.value.id),
      fullName: computed(() => `${user.value.firstName} ${user.value.lastName}`),
      email: computed(() => user.value.emailAddress),
      isPaid: computed(() => account.value.isPaid),
      administrator: computed(() => user.value.administrator),
      companyId: computed(() => user.value.companyId),
      userType: computed(() => user.value.userType),
      inOwnerCompany: computed(() => user.value.inOwnerCompany),
      isAccount: computed(() => user.value.userType === 'account'),
      isContact: computed(() => user.value.userType === 'contact'),
      isCollaborator: computed(() => user.value.userType === 'collaborator'),
      themeCSS: computed(() => buildThemeCSS(branding.value.theme.color)),
      // These need dependency from Vuex store removed
      isFiltersCollapsed: computed(() => store.state.layout.isFiltersCollapsed),
      hasOpenQuickView: computed(() => store.state.layout.hasOpenQuickView.hasOpenQuickView),
      isAlertBannerActive: computed(() => store.getters['layout/isAlertBannerActive']),
      isSidebarCollapsed: computed(() => store.getters['layout/isSidebarCollapsed']),
      shouldUseAppShell,
      hasSidebar: computed(() => store.getters['layout/hasSidebar']),
    };
  },
};
</script>
<template>
  <div
    class="App"
    :data-user-id="userId"
    :data-company-id="companyId"
    :data-user-admin="administrator"
    :class="{
      'in-owner-company': inOwnerCompany,
      'is-admin': administrator,
      'is-account': isAccount,
      'is-collaborator': isCollaborator,
      'is-contact': isContact,
      'alert-banner--is-active': isAlertBannerActive,
      'filter-sidebar-visible': !isFiltersCollapsed,
      'content-with-sidenav': shouldUseAppShell,
      'has-sidebar': hasSidebar,
      'has-open-quickview': hasOpenQuickView,
      'sidebar-closed': isSidebarCollapsed,
    }"
  >
    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component, vue/no-v-html -->
    <component :is="'style'" v-html="themeCSS" />

    <RouterView v-if="isHybridReady" />
    <HybridPopoverContainer />

    <!-- legacy TKO components -->
    <template v-if="isHybridReady">
      <TkoComponent name="hybrid-page-extras" />
      <TkoComponent name="section-dashboard-widgets" />
      <TkoComponent name="section-flash-alerts" />
      <TkoComponent name="widget-timers-list" />
    </template>

    <div id="ModalContainer">
      <HybridModals />
    </div>
    <HybridQuickViews />

    <ProfilePopoverSingleton />
    <WindowEvents />
    <MountingPortal mount-to="body" append>
      <PortalTarget name="body" multiple />
    </MountingPortal>
  </div>
</template>
