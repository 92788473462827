import { render, staticRenderFns } from "./QuickViewContainer.vue?vue&type=template&id=10f0a552&scoped=true&"
import script from "./QuickViewContainer.js?vue&type=script&lang=js&"
export * from "./QuickViewContainer.js?vue&type=script&lang=js&"
import style0 from "./QuickViewContainer.scss?vue&type=style&index=0&id=10f0a552&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@7.0.0-bridge.0_@babel+core@7.23.2_supports-color@9.4.0___css-lo_cxymzsvmby4qf2mwggx3a2wt5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f0a552",
  null
  
)

export default component.exports