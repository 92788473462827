import Vue from 'vue';
import { STATUSES, DROPBOX_503, DROPBOX_URL } from './constants';

const messages = {
  AccessDenied: () => Vue.t('Access Denied'),
  AccessDeniedDetail: () =>
    Vue.t(
      'The server has denied you access. Your permissions may have been changed. If this continues try logging out and back in again.',
    ),
  NotFound: () => Vue.t('Not Found'),
  NotFoundDetail: () => Vue.t('Sorry, either that resource does not exist or you do not have permission to access it.'),
  NetworkTimeout: () => Vue.t('Network Timeout'),
  NetworkTimeoutDetail: () =>
    Vue.t(
      'Please check your internet connection and try again. Email support@teamwork.com if you still have problems.',
    ),
  ServerRejected: () => Vue.t('Server Rejected'),
  ServerRejectedDetail: () => Vue.t('Sorry the server rejected this request.'),
  ServerError: () => Vue.t('Server Error'),
  ServerErrorDetail: () =>
    Vue.t(
      'Sorry the server ran into a problem processing this request. The killer monkeys will be automatically dispatched to fix this.',
    ),
};

const integrationsCall = (error) => error.request.url && error.request.url.includes('integrations/');

export default {
  [STATUSES.UNAUTH](error, store) {
    if (error.config.redirectIfAccessDenied) {
      if (store) {
        store.commit('user/loginAgain');
      }
    } else if (!error.config.suppressLoginModal) {
      if (
        !window.app.isShowingLoginModal() &&
        !(window.app.currentRoute && window.app.currentRoute().page === 'login')
      ) {
        window.app.showLoginModal();
      }
    }
  },

  [STATUSES.UNAVAILABLE](error) {
    if (error.response.data.MESSAGE === DROPBOX_503 && error.request.url === DROPBOX_URL) {
      console.log('503 Dropbox account inaccessible', error);
    }
    if (!integrationsCall(error)) {
      console.log('503 detected', error);
    }
  },

  [STATUSES.BADGATEWAY](error) {
    console.log('502 detected', error);
  },

  [STATUSES.NOTFOUND](error, store) {
    if (store) {
      store.dispatch('notifications/flashes/error', {
        title: messages.NotFound(),
        msg: messages.NotFoundDetail(),
      });
      // TODO - needs implementation! loadUpdate and if fail redirect to dashboard
      store.dispatch('project/current/changeNotification');
    }
  },

  [STATUSES.FORBIDDEN](error, store) {
    if (store) {
      store.dispatch('notifications/flashes/error', {
        title: messages.AccessDenied(),
        msg:
          error.response.statusText.toLowerCase() === 'access denied'
            ? messages.AccessDeniedDetail()
            : error.response.statusText,
      });
    }
  },

  [STATUSES.BADRQ](error, store) {
    if (store) {
      const data = error.response.data;
      let msg = data && (data.message || data.MESSAGE || (data.errors && Object.values(data.errors)[0]));
      if (msg) {
        msg = msg.Message || msg;
        if (msg.detail) {
          msg = msg.detail;
          console.error(`API v3: ${msg}`);
          const parts = msg.split(': ');
          if (parts.length > 1) {
            msg = parts[parts.length - 1];
          }
          msg = msg[0].toUpperCase() + msg.slice(1);
        }
        store.dispatch('notifications/flashes/errorExclaim', { msg });
      } else {
        store.dispatch('notifications/flashes/error', {
          title: messages.ServerRejected(),
          msg: messages.ServerRejectedDetail(),
        });
      }
    }
  },

  noResponse(error, store) {
    if (store && error.request) {
      // Network Timeout
      store.dispatch('notifications/flashes/error', {
        title: messages.NetworkTimeout(),
        msg: messages.NetworkTimeoutDetail(),
      });
    }
  },

  default(error, store) {
    if (store) {
      const data = error.response.data;
      let msg = data && (data.message || data.MESSAGE || (data.errors && Object.values(data.errors)[0]));
      if (msg) {
        msg = msg.Message || msg;
        if (msg.detail) {
          msg = msg.detail;
          console.error(`API v3: ${msg}`);
          const parts = msg.split(': ');
          if (parts.length > 1) {
            msg = parts[parts.length - 1];
          }
          msg = msg[0].toUpperCase() + msg.slice(1);
        }
        store.dispatch('notifications/flashes/errorExclaim', { msg });
      } else {
        store.dispatch('notifications/flashes/error', {
          title: messages.ServerError(),
          msg: messages.ServerErrorDetail(),
        });
      }
    }
  },
};
