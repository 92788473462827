module.exports = {
	"modal-logIn": "startup",
	"hybrid-page-extras": "hybrid",
	"hybrid-placeholder": "hybrid",
	"section-sidebar": "hybrid",
	"section-modals": "hybrid",
	"section-quick-views": "hybrid",
	"section-flash-alerts": "hybrid",
	"section-dashboard-widgets": "hybrid",
	"list-state-blank": "core",
	"list-state-error": "core",
	"list-state-loading-new": "core",
	"list-state-loading": "core",
	"list-state-onboarding": "core",
	"modal-addOrEditTimer": "core",
	"modal-contactForm": "core",
	"modal-personalTasks": "core",
	"modal-trialUpgrade": "core",
	"titlebar": "core",
	"ui-checkbox-icon": "core",
	"ui-common-icon": "core",
	"ui-gif": "core",
	"ui-menuActions": "core",
	"ui-milestone-icon": "core",
	"ui-project-dropdown-button": "core",
	"ui-tag": "core",
	"widget-add-or-edit-comment": "core",
	"widget-add-or-edit-message": "core",
	"widget-available-people-picker": "core",
	"widget-avatar-row": "core",
	"widget-comment-icon": "core",
	"widget-copy-text": "core",
	"widget-include-deleted-users-checkbox": "core",
	"widget-itemFilter": "core",
	"widget-fte-vertical-layout": "core",
	"widget-custom-field-filter": "core",
	"widget-custom-field-filter-item": "core",
	"widget-people-picker-new": "core",
	"widget-people-picker-tipped-followers": "core",
	"widget-people-picker": "core",
	"widget-projects-dropdown-new": "core",
	"widget-projects-dropdown": "core",
	"widget-quick-view": "core",
	"widget-quickView-header": "core",
	"widget-timer": "core",
	"widget-timers-list": "core",
	"widget-upgrade-path": "core",
	"widget-item-notify-picker": "peoplepicker",
	"widget-item-privacy-picker": "peoplepicker",
	"widget-people-picker-new": "peoplepicker",
	"modal-addOrEditDashboard": "dashboardnew",
	"modal-addOrEditDashboardPanel": "dashboardnew",
	"modal-newDashboardMigration": "dashboardnew",
	"page-home-sidebar": "dashboardnew",
	"page-home": "dashboardnew",
	"section-activity-list": "dashboardnew",
	"section-comments-list": "dashboardnew",
	"section-dashboard-metrics": "dashboardnew",
	"section-dashboards-filter": "dashboardnew",
	"section-event-details-header": "dashboardnew",
	"section-event-details": "dashboardnew",
	"section-events-filter": "dashboardnew",
	"section-events-list-new": "dashboardnew",
	"section-help-doc-header": "dashboardnew",
	"section-help-doc": "dashboardnew",
	"section-home-activity": "dashboardnew",
	"section-home-comments": "dashboardnew",
	"section-home-dashboards": "dashboardnew",
	"section-home-events": "dashboardnew",
	"section-home-messages": "dashboardnew",
	"section-home-projects": "dashboardnew",
	"section-home-shortcuts": "dashboardnew",
	"section-home-today": "dashboardnew",
	"section-messages-list": "dashboardnew",
	"section-projects-list-new": "dashboardnew",
	"section-quick-view-events-header-buttons": "dashboardnew",
	"section-quick-view-risks": "dashboardnew",
	"section-quick-view-today-settings": "dashboardnew",
	"section-summary-filter-icons": "dashboardnew",
	"ui-blank-card": "dashboardnew",
	"ui-breadcrumb": "dashboardnew",
	"ui-comment-count": "dashboardnew",
	"ui-complete-button": "dashboardnew",
	"ui-label": "dashboardnew",
	"ui-metric-chart-donut": "dashboardnew",
	"ui-metric-chart-horizontal-bar": "dashboardnew",
	"ui-metric-chart-vertical-bar": "dashboardnew",
	"ui-metric-range": "dashboardnew",
	"ui-metric": "dashboardnew",
	"ui-project-header": "dashboardnew",
	"ui-star": "dashboardnew",
	"ui-text-area": "dashboardnew",
	"ui-unread-status": "dashboardnew",
	"widget-activity": "dashboardnew",
	"widget-attachment": "dashboardnew",
	"widget-attachments-count": "dashboardnew",
	"widget-attachments-list": "dashboardnew",
	"widget-comment": "dashboardnew",
	"widget-dashboard-message": "dashboardnew",
	"widget-event": "dashboardnew",
	"widget-message": "dashboardnew",
	"widget-metric-activity-distribution": "dashboardnew",
	"widget-metric-billable-time": "dashboardnew",
	"widget-metric-billed-time": "dashboardnew",
	"widget-metric-events-list": "dashboardnew",
	"widget-metric-milestones-list": "dashboardnew",
	"widget-metric-milestones": "dashboardnew",
	"widget-metric-projects-digest": "dashboardnew",
	"widget-metric-project-health": "dashboardnew",
	"widget-metric-project-updates-list": "dashboardnew",
	"widget-metric-projects-distribution": "dashboardnew",
	"widget-metric-risks-list": "dashboardnew",
	"widget-metric-risks": "dashboardnew",
	"widget-metric-tasks-breakdown": "dashboardnew",
	"widget-metric-tasks-burndown-settings": "dashboardnew",
	"widget-metric-tasks-burndown": "dashboardnew",
	"widget-metric-tasks": "dashboardnew",
	"widget-project": "dashboardnew",
	"widget-project-metrics": "dashboardnew",
	"widget-quick-jump-projects": "dashboardnew",
	"widget-related-users": "dashboardnew",
	"widget-shortcut": "dashboardnew",
	"widget-tabs-list": "dashboardnew",
	"widget-user-metrics": "dashboardnew",
	"widget-timeline": "dashboardnew",
	"modal-addOrEditDataView": "views",
	"modal-customiseView": "views",
	"ui-table-view": "views",
	"widget-view-manager": "views",
	"ui-pieChart": "summary",
	"section-project-overview-summary": "summary",
	"section-quick-view-breakdown": "summary",
	"section-quick-view-comments-header-buttons": "summary",
	"section-quick-view-messages": "summary",
	"section-quick-view-messages-header-buttons": "summary",
	"section-quick-view-milestones-header-buttons": "summary",
	"section-quick-view-people-header-buttons": "summary",
	"section-quick-view-people-list": "summary",
	"section-quick-view-person": "summary",
	"section-quick-view-tasks": "summary",
	"section-quick-view-tasks-header-buttons": "summary",
	"section-quick-view-time": "summary",
	"widget-tags-bar": "summary",
	"widget-task-breakdown-list": "summary",
	"modal-addOrEditCustomField": "customFields",
	"section-custom-fields-list": "customFields",
	"widget-link-custom-fields": "customFields",
	"widget-link-custom-fields-url-input": "customFields",
	"modal-addOrEditTeam": "teams",
	"modal-chooseDefaultProjects": "teams",
	"modal-choosePeople": "teams",
	"modal-chooseTeams": "teams",
	"page-team": "teams",
	"section-people-teams": "teams",
	"section-team-header": "teams",
	"section-teams-list": "teams",
	"section-teams-projects": "teams",
	"section-project-updates-header-buttons": "projectUpdates",
	"section-project-updates-list": "projectUpdates",
	"widget-project-update": "projectUpdates",
	"modal-changeProjectOwner": "projectUpdates",
	"modal-addOrEditProjectUpdate": "projectUpdates",
	"modal-requestProjectUpdate": "projectUpdates",
	"section-activityTimeline": "activity",
	"section-activityTimeline-byProject": "activity",
	"section-dashboard-activity": "activity",
	"section-project-overview-activity": "activity",
	"section-audit-history-header-buttons": "audit",
	"section-audit-history": "audit",
	"widget-audit-entry": "audit",
	"widget-tags-editor": "tags",
	"widget-tags-pickerFormElement": "tags",
	"widget-tags-addIcon": "tags",
	"widget-tags-list": "tags",
	"widget-tags-list-standalone": "tags",
	"widget-tags-special": "tags",
	"modal-reactions": "reactions",
	"widget-reactions": "reactions",
	"widget-reactions-list": "reactions",
	"section-dashboard-filter": "dash",
	"section-dashboard-latemilestones": "dash",
	"section-dashboard-latetasks": "dash",
	"section-dashboard-todayevents": "dash",
	"section-dashboard-todaysmilestones": "dash",
	"section-dashboard-todaystasks": "dash",
	"section-dashboard-upcomingmilestones": "dash",
	"section-dashboard-upcomingtasks": "dash",
	"section-events-list": "dash",
	"modal-onboardUser": "dash",
	"modal-onboardInviteUsers": "dash",
	"modal-trialOrPaymentExpiry": "dash",
	"page-welcome": "welcome",
	"page-projects": "projects",
	"page-projects-sidebar": "projects",
	"section-project-report": "projects",
	"section-project-report-header-buttons": "projects",
	"section-projects-list": "projects",
	"section-projects-chart": "projects",
	"widget-tipped-project-people-list": "projects",
	"widget-dateRangePicker": "projects",
	"widget-projects-chart": "projects",
	"widget-taskTimeline-chart": "projects",
	"widget-sort": "projects",
	"widget-project-template": "projects",
	"widget-project-template-dropdown": "projects",
	"section-project-timeline": "projects",
	"widget-project-stats-dropdown": "projects",
	"widget-project-template-timeline": "projects",
	"section-project-overview": "overview",
	"section-project-header-buttons-optionsBut": "overview",
	"section-project-overview-header-buttons": "overview",
	"section-project-overview-header-settings-button": "overview",
	"section-project-overview-upcoming-events": "overview",
	"sidebar-date-custom-field": "overview",
	"sidebar-url-custom-field": "overview",
	"sidebar-project-overview": "overview",
	"widget-project-summary-people": "overview",
	"page-basecamp-next": "import",
	"page-basecamp-classic": "import",
	"section-everything-comments": "comments",
	"section-everything-comments-new": "comments",
	"section-project-comments": "comments",
	"sidebar-project-comments": "comments",
	"section-project-comments-header-buttons": "comments",
	"modal-commentProperties": "comments",
	"modal-editNotebookDetails": "notebooks",
	"modal-printOrDownloadNotebook": "notebooks",
	"section-everything-notebooks": "notebooks",
	"section-project-notebooks-collapsedMode": "notebooks",
	"section-project-notebooks-create": "notebooks",
	"section-project-notebooks-edit": "notebooks",
	"section-project-notebooks-gridMode": "notebooks",
	"section-project-notebooks-header-buttons": "notebooks",
	"section-project-notebooks-listMode": "notebooks",
	"section-project-notebooks": "notebooks",
	"sidebar-project-notebooks": "notebooks",
	"widget-tipped-notebook-versions": "notebooks",
	"modal-addOrEditLink": "links",
	"modal-projectPicker": "links",
	"modal-copyLink": "links",
	"modal-exportLinks": "links",
	"modal-linkOverlay": "links",
	"section-everything-links": "links",
	"section-link-details": "links",
	"section-links-list": "links",
	"section-project-links": "links",
	"section-project-links-header-buttons": "links",
	"widget-link-body": "links",
	"widget-link-details-sidebar": "links",
	"widget-links-listing-sidebar": "links",
	"sidebar-project-links": "links",
	"modal-splitMessageThread": "messages",
	"section-everything-messages": "messages",
	"section-everything-messages-new": "messages",
	"section-project-messages": "messages",
	"section-project-messages-create": "messages",
	"section-project-messages-edit": "messages",
	"section-project-messages-header-buttons": "messages",
	"sidebar-project-messages": "messages",
	"widget-completedTasks-filter": "everythingTasks",
	"widget-completedTasks-list": "everythingTasks",
	"section-everything-tasks": "everythingTasks",
	"section-everything-completedTasks": "everythingTasks",
	"modal-add-edit-report": "reports",
	"page-reporting-sidebar": "reports",
	"page-reporting": "reports",
	"section-milestones-filter-summary": "reports",
	"section-report-details": "reports",
	"section-report-list": "reports",
	"section-report-row": "reports",
	"section-reporting-reports": "reports",
	"section-risks-filter-summary": "reports",
	"section-sidebar-reporting": "reports",
	"section-tasks-filter-summary": "reports",
	"section-time-filter-summary": "reports",
	"section-workload-filter-summary": "reports",
	"widget-report-actions": "reports",
	"widget-report-avatar-list": "reports",
	"widget-report-filters": "reports",
	"widget-report-schedule": "reports",
	"widget-report-summary": "reports",
	"widget-report-summary-list": "reports",
	"modal-addOrEditSavedFilter": "filters",
	"modal-shareFilterAsLink": "filters",
	"section-activity-filter": "filters",
	"section-calendar-filter": "filters",
	"section-comments-filter": "filters",
	"section-files-filter": "filters",
	"section-links-filter": "filters",
	"section-messages-filter": "filters",
	"section-milestones-filter": "filters",
	"section-notebooks-filter": "filters",
	"section-people-filter": "filters",
	"section-projects-chart-filter": "filters",
	"section-projects-list-filter": "filters",
	"section-projects-portfolio-filter": "filters",
	"section-risks-filter": "filters",
	"section-sidebar-filters": "filters",
	"section-tasks-filter": "filters",
	"section-time-filter": "filters",
	"section-tipped-filters": "filters",
	"section-workload-filter": "filters",
	"section-workload-people-filter": "filters",
	"widget-filter-action": "filters",
	"widget-filter-list": "filters",
	"widget-filter-people": "filters",
	"widget-filter-tags": "filters",
	"widget-filter-title": "filters",
	"modal-copyRisks": "risks",
	"modal-addOrEditRiskEntry": "risks",
	"section-everything-risks": "risks",
	"section-project-risks": "risks",
	"section-project-risks-header-buttons": "risks",
	"section-risks-list": "risks",
	"widget-risks-filter": "risks",
	"page-everything": "everything",
	"page-planning": "planning",
	"page-embed": "embed",
	"page-integrations-project-overview-summary": "embed",
	"page-integrations-task": "embed",
	"modal-addOrEditTaskList": "projectTasks",
	"sidebar-project-tasks": "projectTasks",
	"section-completed-tasks-list": "projectTasks",
	"section-project-tasks-header-buttons": "projectTasks",
	"section-project-tasks": "projectTasks",
	"sidebar-project-task": "projectTasks",
	"section-subtasks-list": "projectTasks",
	"section-tasklists-list": "projectTasks",
	"section-taskList": "projectTasks",
	"section-tasks-list": "projectTasks",
	"widget-add-automation-button": "projectTasks",
	"widget-add-tasklist-button": "projectTasks",
	"widget-search-input": "projectTasks",
	"widget-text-input": "projectTasks",
	"modal-addOrEditBoard": "sharedBoardComponents",
	"modal-addOrEditColumnTrigger": "sharedBoardComponents",
	"modal-changeBoardColumn": "sharedBoardComponents",
	"section-board-column": "sharedBoardComponents",
	"section-boards-list": "sharedBoardComponents",
	"section-cards-backlog": "sharedBoardComponents",
	"section-cards-backlog-filter": "sharedBoardComponents",
	"section-quick-view-archived-cards-list": "sharedBoardComponents",
	"section-quick-view-column-settings": "sharedBoardComponents",
	"section-cards-list": "sharedBoardComponents",
	"widget-board-column-label": "sharedBoardComponents",
	"section-project-tasks-board": "taskCards",
	"ui-estimatedTimeInput": "taskCards",
	"widget-column-triggers-icon": "taskCards",
	"widget-task-card": "taskCards",
	"widget-task-card-dropdown": "taskCards",
	"widget-task-card-options": "taskCards",
	"section-projects-portfolio": "projectsPortfolio",
	"section-portfolio-board": "projectsPortfolio",
	"widget-project-card": "projectsPortfolio",
	"section-projects-templates": "projectsTemplates",
	"modal-addOrEditTaskListTemplate": "taskTemplates",
	"page-task-templates": "taskTemplates",
	"page-task-templates-sidebar": "taskTemplates",
	"section-tasklist-template": "taskTemplates",
	"section-tasklist-templates-list": "taskTemplates",
	"modal-addOrEditTask": "tasksShared",
	"section-addOrEditTask": "tasksShared",
	"section-editTaskPredecessors": "tasksShared",
	"section-filtered-tasks": "tasksShared",
	"section-project-task-proofs": "tasksShared",
	"section-project-task-time": "tasksShared",
	"section-project-task": "tasksShared",
	"section-project-tasklist": "tasksShared",
	"section-task-details": "tasksShared",
	"widget-followers-list": "tasksShared",
	"widget-personal-tasks": "tasksShared",
	"widget-quick-date-set": "tasksShared",
	"widget-quick-task": "tasksShared",
	"widget-task-dependents-link": "tasksShared",
	"widget-task-dropdown": "tasksShared",
	"widget-task-assignee": "tasksShared",
	"widget-task-estimated-time": "tasksShared",
	"widget-task-files-list": "tasksShared",
	"widget-task-harvest-timer": "tasksShared",
	"widget-task-options": "tasksShared",
	"widget-task-predecessors-link": "tasksShared",
	"widget-task-related-tasks": "tasksShared",
	"widget-task-reminders-list": "tasksShared",
	"widget-task-row": "tasksShared",
	"widget-tasklist-dropdown": "tasksShared",
	"widget-tipped-task-comments": "tasksShared",
	"widget-tipped-task-dates": "tasksShared",
	"widget-tipped-task-followers": "tasksShared",
	"widget-tipped-task-priority": "tasksShared",
	"widget-tipped-task-reminder": "tasksShared",
	"widget-tipped-task-progress": "tasksShared",
	"widget-tipped-task-recurring": "tasksShared",
	"widget-tipped-task-time": "tasksShared",
	"modal-addOrEditNewTaskDefaults": "tasksSecondary",
	"modal-assignTemplateTaskRoles": "tasksSecondary",
	"modal-printableTaskDetails": "tasksSecondary",
	"modal-recurringTasksPrompt": "tasksSecondary",
	"modal-importTasks": "tasksSecondary",
	"modal-taskActivity": "tasksSecondary",
	"section-desk-ticket": "tasksSecondary",
	"widget-tipped-timer-task-info": "tasksSecondary",
	"modal-addOrEditTaskReminder": "taskModals",
	"modal-addOrEditTimeEstimate": "taskModals",
	"modal-addTasksFromTemplate": "taskModals",
	"modal-dependenciesPicker": "taskModals",
	"modal-quicklyAddTasks": "taskModals",
	"modal-saveTaskListAsTemplate": "taskModals",
	"modal-sendTaskReminder": "taskModals",
	"modal-taskFollowers": "taskModals",
	"modal-taskListReport": "taskModals",
	"modal-taskListTimeReport": "taskModals",
	"modal-attachFilesToTask": "taskModals",
	"modal-bulkEditTasks": "taskModals",
	"modal-currentTaskReminders": "taskModals",
	"modal-taskProperties": "taskModals",
	"modal-shareAsLink": "taskModals",
	"section-create-desk-ticket": "taskModals",
	"modal-selectMultipleTasks": "taskModals",
	"widget-basic-task-row": "taskModals",
	"widget-select-tasks": "taskModals",
	"widget-stage-picker": "taskModals",
	"widget-task-options-simple": "taskModals",
	"f-subtask-icon": "taskModals",
	"section-gantt": "gantt",
	"modal-ganttChartHelp": "gantt",
	"modal-ganttChartReport": "gantt",
	"modal-addOrEditMilestone": "milestones",
	"section-everything-milestones": "milestones",
	"section-milestone-details": "milestones",
	"section-milestones-calendar": "milestones",
	"section-milestones-list": "milestones",
	"section-project-milestones": "milestones",
	"section-project-milestones-header-buttons": "milestones",
	"sidebar-project-milestones": "milestones",
	"widget-milestone-attach-tasklist": "milestones",
	"widget-milestone-body": "milestones",
	"widget-milestone-details-sidebar": "milestones",
	"widget-milestone-sidebar-miniCalendar": "milestones",
	"widget-milestones-listing-sidebar": "milestones",
	"modal-addMultipleMilestones": "milestoneModals",
	"modal-printMilestoneCalendar": "milestoneModals",
	"modal-milestoneProperties": "milestoneModals",
	"modal-duplicateMilestone": "milestoneModals",
	"modal-milestoneActivity": "milestoneModals",
	"modal-milestoneICalFeed": "milestoneModals",
	"modal-moveMilestoneDates": "milestoneModals",
	"modal-moveMilestoneProject": "milestoneModals",
	"modal-moveExpenseProject": "billing",
	"section-project-billing": "billing",
	"section-project-billing-header-buttons": "billing",
	"section-billing-expenses": "billing",
	"section-billing-time": "billing",
	"widget-invoice-details": "billing",
	"modal-addOrEditExpense": "billingModals",
	"modal-addOrEditInvoice": "billingModals",
	"modal-exportInvoice": "billingModals",
	"modal-exportInvoiceBlinksale": "billingModals",
	"modal-exportInvoiceFreshbooks": "billingModals",
	"modal-exportInvoiceHarvest": "billingModals",
	"modal-exportInvoiceQuickbooks": "billingModals",
	"modal-exportInvoiceXero": "billingModals",
	"modal-exportInvoiceXeroFile": "billingModals",
	"modal-selectInvoice": "billingModals",
	"modal-addOrEditEvent": "calendar",
	"modal-addOrEditEventType": "calendar",
	"modal-calendarDownloadAgenda": "calendar",
	"modal-calendarICalFeeds": "calendar",
	"modal-editEventTypes": "calendar",
	"modal-editOrDeleteRecurringEvent": "calendar",
	"modal-eventProperties": "calendar",
	"modal-previewEvent": "calendar",
	"page-calendar": "calendar",
	"widget-month-picker": "calendar",
	"widget-tipped-calendar-tasks": "calendar",
	"widget-tipped-event-info": "calendar",
	"modal-addOrEditStatus": "statuses",
	"page-statuses": "statuses",
	"section-statuses-overview": "statuses",
	"section-statuses-timeline": "statuses",
	"section-team-statuses": "statuses",
	"modal-addOrEditProjectRole": "projectPeople",
	"modal-addPeopleToProject": "projectPeople",
	"modal-manageProjectObservers": "projectPeople",
	"modal-removeUserFromProject": "projectPeople",
	"section-project-people": "projectPeople",
	"section-project-people-header-buttons": "projectPeople",
	"section-project-people-roles": "projectPeople",
	"widget-tipped-projectrole-people": "projectPeople",
	"page-company": "people",
	"page-people": "people",
	"page-person": "people",
	"section-companies-list": "people",
	"section-companiesList-cardsMode": "people",
	"section-companiesList-expandedMode": "people",
	"section-companiesList-listMode": "people",
	"section-company-header": "people",
	"section-company-tab-loggedtime": "people",
	"section-company-tab-profile": "people",
	"section-company-tab-projects": "people",
	"section-people-companies": "people",
	"section-people-list-new": "people",
	"section-people-login-history-new": "people",
	"section-people-loginHistory": "people",
	"section-people-people-cards": "people",
	"section-people-people-expanded": "people",
	"section-people-people-list": "people",
	"section-people-people": "people",
	"section-people-ribbon": "people",
	"section-person-activitychart": "people",
	"section-person-companyPanel": "people",
	"section-person-header": "people",
	"section-person-tab-activity": "people",
	"section-person-tab-boards": "people",
	"section-person-tab-completed": "people",
	"section-person-tab-details": "people",
	"section-person-tab-events": "people",
	"section-person-tab-milestones": "people",
	"section-person-tab-projects": "people",
	"section-person-tab-tasks": "people",
	"section-person-tab-teams": "people",
	"section-person-tab-time": "people",
	"section-person-time-graph": "people",
	"ui-company-dropdown": "people",
	"ui-person-dropdown": "people",
	"ui-timelog-dropdown": "people",
	"widget-inviteBadge": "people",
	"widget-permission-row": "people",
	"widget-time-graph-controls": "people",
	"modal-movePeopleFromCompany": "peopleSecondary",
	"widget-userPicker": "peopleSecondary",
	"modal-importPeople": "peopleSecondary",
	"modal-editImportedUsers": "peopleSecondary",
	"modal-addCompany": "addUser",
	"modal-addOrEditCompany": "addUser",
	"modal-addOrEditPerson": "addUser",
	"modal-userProjectsAccess": "addUser",
	"modal-userConfirmDelete": "addUser",
	"modal-userReassignItems": "addUser",
	"modal-userDowngradeToContact": "addUser",
	"modal-turnOnAPIAccess": "addUser",
	"modal-deletedUsers": "peopleModals",
	"modal-inviteNewUsers": "peopleModals",
	"modal-userProjectPermissions": "peopleModals",
	"modal-bulkEditProjectPermissions": "peopleModals",
	"modal-sendInvites": "peopleModals",
	"modal-selectProjects": "peopleModals",
	"page-search": "search",
	"widget-tipped-event-search-page": "search",
	"page-cancel-account": "settings",
	"section-settings-customfields": "settings",
	"section-settings-email": "settings",
	"section-settings-export": "settings",
	"section-settings-forms": "settings",
	"section-settings-invoicehistory": "settings",
	"section-settings-beta-features": "settings",
	"section-settings-logo": "settings",
	"section-settings-sso": "settings",
	"section-settings-subscription": "settings",
	"section-settings-subscriptionPerUser": "settings",
	"section-settings-tags": "settings",
	"section-tags-list": "settings",
	"section-settings-templates": "settings",
	"section-settings-webhooks": "settings",
	"section-sidebar-categories": "settings",
	"section-quick-view-grandfathered": "settings",
	"section-quick-view-successreport": "settings",
	"modal-addOrEditWebhook": "settings",
	"modal-addTag": "settings",
	"modal-boxcomConfig": "settings",
	"modal-dropboxConfig": "settings",
	"modal-oneDriveConfig": "settings",
	"modal-changePricePlan": "settings",
	"modal-updateCreditCard": "settings",
	"modal-changeReceiptReceivers": "settings",
	"modal-downgradeToFree": "settings",
	"modal-dropdown": "settings",
	"modal-editCustomCSS": "settings",
	"modal-editText": "settings",
	"modal-enterpriseForm": "settings",
	"modal-freshbooksSelectBusiness": "settings",
	"modal-gSuiteDomains": "settings",
	"modal-importAsana": "settings",
	"modal-importBasecamp": "settings",
	"modal-importBasecamp3": "settings",
	"modal-importWrike": "settings",
	"modal-importTrello": "settings",
	"modal-importClickup": "settings",
	"modal-importClickupSuccess": "settings",
	"modal-importMonday": "settings",
	"modal-invoiceView": "settings",
	"modal-newUserPermissions": "settings",
	"modal-viewLogs": "settings",
	"modal-fs-usage-report": "settings",
	"widget-feedback-form": "settings",
	"widget-sso-form": "settings",
	"widget-integration": "settings",
	"widget-integration-disabled": "settings",
	"widget-integration-devportal": "settings",
	"widget-new-user-project-permission-row": "settings",
	"widget-newUserPermissions": "settings",
	"widget-settings-webhooks": "settings",
	"widget-tipped-cloud-account-info": "settings",
	"widget-uploadBox": "settings",
	"widget-user-project-permission-row": "settings",
	"f-setting-group": "settings",
	"widget-spaces-list": "integrations",
	"widget-crm-deals-list": "integrations",
	"page-project": "project",
	"page-project-sidebar": "project",
	"modal-cloudFileAccess": "projectSettings",
	"modal-harvestConfirm": "projectSettings",
	"section-project-settings": "projectSettings",
	"section-project-settings-header-buttons": "projectSettings",
	"section-project-settings-customfields": "projectSettings",
	"section-project-settings-general": "projectSettings",
	"section-project-settings-integrations": "projectSettings",
	"section-project-settings-notifications": "projectSettings",
	"section-project-settings-tags": "projectSettings",
	"section-project-settings-webhooks": "projectSettings",
	"section-quick-view-project-summary-settings": "projectSettings",
	"modal-addOrEditRange": "projectBudget",
	"modal-budgetExceeded": "projectBudget",
	"section-budget-notifications-list": "projectBudget",
	"section-budget-usage-charts-list": "projectBudget",
	"section-ranges-list": "projectBudget",
	"ui-input-percent": "projectBudget",
	"widget-budget-capacity-used": "projectBudget",
	"widget-budget-history": "projectBudget",
	"widget-budget-notification": "projectBudget",
	"widget-budget-remaining": "projectBudget",
	"widget-budget-usage-chart": "projectBudget",
	"widget-color-picker": "projectBudget",
	"widget-metric-budgets": "projectBudget",
	"widget-repeat-options": "projectBudget",
	"modal-fileLinkShare": "files",
	"section-everything-files": "files",
	"section-files-list": "files",
	"section-files-expanded": "files",
	"section-files-cards": "files",
	"sidebar-project-files": "files",
	"sidebar-project-file": "files",
	"section-project-files-header-buttons": "files",
	"section-project-files": "files",
	"section-project-files-cloud-buttons": "files",
	"section-project-files-create": "files",
	"section-project-files-edit": "files",
	"widget-file-actions": "files",
	"widget-file-integration-options": "files",
	"modal-cloudFileBrowser": "filesModals",
	"modal-cloudFileAccessRights": "filesModals",
	"modal-cloudStorageConnectStatus": "filesModals",
	"modal-cloudStorageDrives": "filesModals",
	"modal-cloudStorageSites": "filesModals",
	"modal-createFolder": "filesModals",
	"modal-filesAttachedTo": "filesModals",
	"modal-documentDropFiles": "filesModals",
	"modal-selectExistingFiles": "filesModals",
	"modal-confirmDeleteFileRevision": "filesModals",
	"modal-projectsAddFiles": "filesModals",
	"modal-projectsEditFileDetails": "filesModals",
	"modal-deleteProjectActivity": "filesModals",
	"modal-cloudLinkChooseCategory": "filesModals",
	"modal-createGoogleDriveItems": "filesModals",
	"modal-duplicateCloudFile": "filesModals",
	"modal-googleDriveAccessRights": "filesModals",
	"modal-fileSelectVersion": "filesModals",
	"modal-filesReplaceFile": "filesModals",
	"modal-filesLockedFilesWarning": "filesModals",
	"widget-uploadDrive": "filesModals",
	"widget-uploadDropbox": "filesModals",
	"widget-uploadOnedrive": "filesModals",
	"widget-uploadOnedriveBusiness": "filesModals",
	"widget-uploadSharePoint": "filesModals",
	"modal-addOrEditTimeEntry": "time",
	"modal-moveTimeLogProject": "time",
	"section-everything-time": "time",
	"section-project-time": "time",
	"section-project-time-header-buttons": "time",
	"sidebar-project-time": "time",
	"widget-time-list": "time",
	"widget-time-filter": "time",
	"widget-time-totals": "time",
	"modal-quick-add-time": "timeModals",
	"modal-startTimer": "timeModals",
	"modal-updateSavedTimer": "timeModals",
	"modal-allTimeLogTime": "timeModals",
	"modal-bulkUpdateTimeLogs": "timeModals",
	"modal-moveTimelogs": "timeModals",
	"modal-chooseTask": "timeModals",
	"modal-addOrEditShortcut": "shortcuts",
	"section-shortcuts-list": "shortcuts",
	"section-quick-view-shortcuts": "shortcuts",
	"section-quick-view-shortcuts-header": "shortcuts",
	"section-everything-workload": "workload",
	"section-workload-overview": "workload",
	"section-workload-overview-list": "workload",
	"section-workload-listing": "workload",
	"section-workload-manage": "workload",
	"section-workload-manage-tasks": "workload",
	"section-quick-view-workload-tasks": "workload",
	"widget-labelButtonGroup": "workload",
	"widget-workload-filter": "workload",
	"widget-toggleSwitch": "widgets",
	"widget-toggleLinkText": "widgets",
	"ui-date-range": "widgets",
	"ui-circle": "widgets",
	"ui-dropMenu": "widgets",
	"ui-filterInfo": "widgets",
	"ui-filterButton": "widgets",
	"ui-icon-tabs": "widgets",
	"ui-intro-filters": "widgets",
	"ui-fullscreenToggleButton": "widgets",
	"ui-sortOptions": "widgets",
	"widget-task-updates-handler": "widgets",
	"ui-progressBar": "widgets",
	"ui-simple-tabs": "widgets",
	"ui-clocked-in-icon": "widgets",
	"section-breadcrumbs": "widgets",
	"widget-tip": "widgets",
	"widget-options": "widgets",
	"widget-loadMore": "widgets",
	"widget-list-picker": "widgets",
	"widget-letters": "widgets",
	"widget-privacy-icon": "widgets",
	"widget-autosave": "widgets",
	"widget-mainTabs": "widgets",
	"widget-subTabs": "widgets",
	"widget-typeahead": "widgets",
	"widget-task-input": "widgets",
	"widget-followBtn": "widgets",
	"widget-usage-hint": "widgets",
	"widget-multiselect-menu": "widgets",
	"widget-quickView-header-CalendarTasks": "widgets",
	"widget-creditcard-notification": "widgets",
	"widget-live-chat": "widgets",
	"widget-create-project-channel": "widgets",
	"widget-copy-text": "widgets",
	"widget-link-project-space": "widgets",
	"modal-addMessage": "frequentModals",
	"modal-addOrEditComment": "frequentModals",
	"modal-addOrEditProject": "frequentModals",
	"modal-editPrivacy": "frequentModals",
	"modal-previewImages": "frequentModals",
	"modal-previewFile": "frequentModals",
	"modal-itemNotifications": "frequentModals",
	"modal-addOrEditCategory": "frequentModals",
	"modal-moveOrCopyItem": "frequentModals",
	"modal-printOptions": "frequentModals",
	"modal-uploadImage": "frequentModals",
	"modal-cloneProject": "projectsModals",
	"modal-editProjectDates": "projectsModals",
	"modal-editProjectsChartDates": "projectsModals",
	"modal-editProjectsChartFilters": "projectsModals",
	"modal-projectActivityReport": "projectsModals",
	"modal-postByProjectEmail": "projectsModals",
	"modal-trashCan": "projectsModals",
	"modal-cloudDisconnect": "settingsModals",
	"modal-dashboardOptions": "settingsModals",
	"modal-upgradePlan": "settingsModals",
	"modal-editAnnouncement": "settingsModals",
	"modal-sendFeedback": "settingsModals",
	"modal-manageCategories": "settingsModals",
	"modal-manageDefaultProjects": "settingsModals",
	"modal-prompt": "lazyModals",
	"modal-hubspot-addLink": "lazyModals",
	"modal-editMessagePostDetails": "lazyModals",
	"modal-postStatusViaEmail": "lazyModals",
	"modal-customizeProjectEmail": "lazyModals",
	"modal-customizeTaskListEmail": "lazyModals",
	"modal-manageApps": "lazyModals",
	"page-serverstats": "rare1",
	"generic-uploadExternal": "rare1",
	"modal-outage": "rare1",
	"modal-productAnnouncement": "rare1",
	"modal-linkedAccounts": "linkedAccounts",
	"modal-newLinkToAccount": "linkedAccounts",
	"modal-downloadChat": "chat",
	"widget-start-chat": "chat",
	"popup-HubSpot": "hubSpot",
	"widget-tags-special-hubspot": "hubSpot",
	"section-reassignOptions": "reassign",
	"modal-enable2FactorAuth": "2fa",
	"modal-referralClaim": "referral",
	"modal-referralEmail": "referral",
	"section-referral-about": "referral",
	"section-referral-banners": "referral",
	"section-referral-latest": "referral",
	"section-referral-terms": "referral",
	"section-referral-tips": "referral",
	"section-settings-referral": "referral",
	"page-test": "pageTest",
	"widget-inline-modal": "pageTest",
	"widget-mentions": "inputs",
	"widget-dateInput": "inputs",
	"widget-durationInput": "inputs",
	"widget-timeInput": "inputs",
	"widget-timeRangeInput": "inputs",
	"widget-timeMomentPicker": "inputs",
	"widget-timePicker": "inputs",
	"widget-tw-autocomplete": "inputs",
	"widget-wysiwyg-editor": "inputs",
	"widget-easy-markdown-editor": "inputs",
	"widget-file-attachment-dropzone": "attachments",
	"widget-file-attachment-inline": "attachments",
	"widget-file-attachment-picker": "attachments",
	"widget-file-viewer": "attachments",
	"widget-file-documenteditor": "attachments",
	"widget-tipped-permission-list": "tips1",
	"widget-tipped-milestone-info": "tips1",
	"widget-tipped-item-created-by": "tips1",
	"widget-tipped-desk-tickets": "tips1",
	"widget-tipped-category": "tips1",
	"widget-tipped-file-card-info": "tips1",
	"widget-tipped-follow-options": "tips1",
	"widget-tipped-likes": "tips1",
	"widget-tipped-notified-list": "tips1",
	"widget-tipped-person": "tips1",
	"widget-tipped-filter": "tips1",
	"widget-tipped-tasks": "tips1",
	"widget-tipped-tags-list": "tips1",
	"widget-tipped-responsible-person": "tips1",
	"widget-tipped-last-comments": "tips1",
	"modal-addOrEditClockIn": "clockInOut",
	"section-clock-ins-list": "clockInOut",
	"modal-perUserPricingQuickSet": "perUserPricing",
	"widget-presence": "ot",
}