/* eslint-disable no-param-reassign,no-return-assign */
import Vue from 'vue';
import { createRecordLoader, API } from '@/store/utils/loader';
import { createBatchLoader } from '@/store/utils/loader/batch';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { mapMutations } from '@/store/utils/record-mapper';
import { lsSyncer, lsRecordsSyncer } from '@/store/utils/localstorage';
import { rewireRecordAccess } from '@/scaffolding/data-loader';
import annex from '@/services/vuex-annex';
import { defaultTypes, defaults } from './recordDefault';
import getters from './getters';
import actions from './actions';

import latest from './modules/latest';
import starred from './modules/starred';
import all from './modules/all';
import filtered from './modules/filtered';
import tasklists, { watchers as tasklistWatchers } from './modules/tasklists';
import people from './modules/people';
import tags from './modules/tags';
import company from './modules/company';
import tasks, { watchers as taskWatchers } from './modules/tasks';
import dependencies from './modules/dependencies';
import teams from './modules/teams';

export const watchers = [
  lsSyncer({
    mutation: 'project/initLocalStorage',
    fields: {
      'last-overview-section': {
        name: 'lastOverviewSection',
        default: 'summary',
      },
      'select-project-last-tab': {
        name: 'selectProjectLastTab',
        default: 'latest',
      },
    },
  }),
  lsRecordsSyncer({
    mutation: 'project/initRecordLocalStorage',
    formatter: (id, field) => `${field}-${id}`,
    fields: {
      ganttlhswidth: {
        name: 'ganttLHSWidth',
        default: 0,
      },
    },
  }),
  {
    mutations: ['project/star', 'project/unstar'],
    callback: ({ dispatch, state }, type, id) => {
      // wait for any pending actions
      annex.promises(state.project.records[id], 'working').then(() => {
        // Invalidate the following data
        dispatch('project/starred/changeNotification');
        dispatch('project/latest/changeNotification');
      });
    },
  },
  {
    mutations: ['project/deleted', 'project/activate', 'project/archive'],
    callback: ({ dispatch }) => {
      // Invalidate the following data
      dispatch('project/all/changeNotification');
      dispatch('project/starred/changeNotification');
      dispatch('project/latest/changeNotification');
    },
  },
  ...taskWatchers,
  ...tasklistWatchers,
];

const mapRecord = (project) => {
  const mapped = { ...project };
  if (!mapped.company) {
    mapped.company = { id: mapped.companyId, name: mapped.companyName };
  }
  if (mapped.customFields && mapped.customFields.length) {
    mapped.customFields = mapped.customFields.map((field) => ({
      ...field,
      customfieldId: field.customFieldId,
    }));
  }
  if (!mapped.customFields) {
    mapped.customFields = [];
  }
  return mapped;
};

export default {
  namespaced: true,
  modules: {
    batch: createBatchLoader({
      namespaced: true,
      config: {
        url: `${API.v3}/projects.json`,
        param: 'projectIds',
        module: 'project',
      },
    }),
    loader: createRecordLoader({
      config: {
        url: (id) => `${API.v2}/projects/${id}.json`,
        params: () => ({
          getPermissions: true,
          getNotificationSettings: true,
          getActivePages: true,
          getDateInfo: true,
          getEmailAddress: true,
          formatMarkdown: false,
          includeProjectOwner: true,
          includeCustomFields: true,
        }),
        mapResponse: (ctx, rs) => rs.data.project,
        mapToIncluded: (ctx, rs) => ({ tags: rs.data.project.tags || [] }),
        includedConfig: { tags: 'tag' },
      },
      actions: {
        // TODO Hybrid
        ...rewireRecordAccess((id) => window.app.projectInfo.update(id)),
      },
    }),
    tasklists,
    tasks,
    latest,
    starred,
    all,
    filtered,
    people,
    dependencies,
    company,
    tags,
    teams,
  },
  getters,
  state: {
    records: {},
    currentProjectId: null,
    lastActiveProjectId: null,
  },
  mutations: {
    current(state, id) {
      state.currentProjectId = id;
      if (state.currentProjectId != null) {
        state.lastActiveProjectId = state.currentProjectId;
      }
    },
    deleteSuccessful: (state, id) => {
      if (state.currentProjectId === id) {
        state.currentProjectId = null;
      }
      if (state.lastActiveProjectId === id) {
        state.lastActiveProjectId = null;
      }
    },
    record(state, project) {
      const defsApplied = typedDefaults(defaultTypes, mapRecord(project));
      // First time saving the record, add all the defaults too.
      const newRec = {
        ...(state.records[project.id] || defaults),
        ...defsApplied,
      };
      // TODO undo
      newRec.activePages.finance = true;
      Vue.set(state.records, newRec.id, newRec);
    },
    records(state, projects) {
      const hash = {};
      projects.forEach((project) => {
        const defsApplied = typedDefaults(defaultTypes, mapRecord(project));
        // First time saving the record, add all the defaults too.
        hash[project.id] = {
          ...(state.records[project.id] || defaults),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    initLocalStorage(state, lsdata) {
      Vue.assign(state, lsdata);
    },
    initRecordLocalStorage(state, { id, payload }) {
      Vue.assign(state.records[id], payload);
    },
    selectProjectLastTab(state, tab) {
      state.selectProjectLastTab = tab;
    },
    skipWeekends(state, payload) {
      state.records[state.currentProjectId].skipWeekends = payload;
    },
    ...mapMutations({
      activate: (state) => {
        state.status = 'active';
      },
      archive: (state) => {
        state.status = 'archived';
      },
      deleted: (state) => {
        state.status = 'deleted';
      },
      star: (state) => {
        state.starred = true;
      },
      unstar: (state) => {
        state.starred = false;
      },
      completed: (state) => {
        state.subStatus = 'completed';
      },
      uncompleted: (state, newStatus) => {
        state.subStatus = newStatus;
      },
      ganttLHSResize: (state, width) => {
        state.ganttLHSWidth = width;
      },
    }),
  },
  actions,
};
