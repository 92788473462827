import { shallowRef, watch } from 'vue-demi';
import { EventEmitter } from 'events';
import { passive } from '@/utils/helpers/promise';
// TODO Hybrid - listen for local postbox messages that mimic a server notification
import postboxService from '@/scaffolding/postbox-service';
import { vueSocket } from '@/scaffolding/socket';
import ApiService from '@/services/api';
import permissions from './permission';
import mapEvent from './mapper';
import notificationHandlers from './handlers';

let store;
let socket;
const connectedPromise = passive();
const eventNoticeEmitter = new EventEmitter();
eventNoticeEmitter.setMaxListeners(10000);

const NotificationService = {
  isConnected: shallowRef(false),
  handlingWebSocketEvent: false,
  eventNoticeEmitter,

  // Instead of connecting a new socket in this service (Hybrid functionality)
  attachConnectedSocket(connectedSocket) {
    socket = connectedSocket;
    // authResult has already come back
    this.isConnected.value = true;
    ApiService.setSocketId(socket.io.engine.id);
    connectedPromise.resolve();
    this.setupListeners();
  },

  setupListeners() {
    socket.on('authResult', (data) => {
      if (data.authenticated) {
        this.isConnected.value = true;
      }

      ApiService.setSocketId(socket.io.engine.id);
      connectedPromise.resolve();
    });

    socket.on('disconnect', () => {
      this.isConnected.value = false;
    });

    socket.on('eventNotice', (data) => this.receiveEvent(data));
  },

  receiveEvent(data) {
    try {
      this.handlingWebSocketEvent = true;
      const { eventInfo } = Array.isArray(data) ? data[0] : data;
      queueMicrotask(() => eventNoticeEmitter.emit('eventNotice', eventInfo));
      const mapped = mapEvent(eventInfo);

      // If a socketId has been routed through TWIM and it's the current user then drop publishing
      // the event notice. Only exception to this is the special threadedLoopback instance:
      // when a request is using an async CFTHREAD to finish processing, we leverage a TWIM call when
      // its done to tell the loggedInUser about the result
      // !! use `threadedLoopback` with care, it loops back an async TWIM event to the current user!!
      // threadedLoopback and THREADEDLOOPBACK are both needed. CF sometimes sets property in all caps
      // Also, onlyThisSocket allows filtering to this specific socket when given threadedLoopback
      const extraData = (mapped.extraInfo && mapped.extraInfo.data) || {};
      const threadedLoopback = extraData.threadedLoopback || extraData.THREADEDLOOPBACK;
      const forThisSocket = mapped.socketId && mapped.socketId === socket.io.engine.id;

      if ((forThisSocket && !threadedLoopback) || (!forThisSocket && extraData.onlyThisSocket)) {
        // keeps the latest up to date in the project switcher
        // as per reacting to all events in `widget-tipped-switch-project`
        store.dispatch('project/latest/changeNotification');
        return;
      }

      // Item Type handlers
      if (notificationHandlers[mapped.itemType]) {
        notificationHandlers[mapped.itemType](store, mapped);
      }

      // Special handling for permissions changes
      if (mapped.event === 'permissions') {
        permissions(store, mapped);
      }
    } finally {
      this.handlingWebSocketEvent = false;
    }
  },
};

let stopWatch = null;
/**
 * Vuex Store Plugin
 */
export const plugin = (storeToRegister) => {
  store = storeToRegister;

  store.watch(
    ({ config }, getters) => getters['account/isFullyReady'] && config.isReady,
    (isReady) => {
      if (isReady) {
        // TODO Hybrid - connect postbox to the socket notification handlers and store
        postboxService(notificationHandlers, store);

        stopWatch?.();
        stopWatch = watch(
          vueSocket,
          () => {
            if (vueSocket.value) {
              NotificationService.attachConnectedSocket(vueSocket.value);
            }
          },
          { immediate: true },
        );
      }
    },
  );
};

export default NotificationService;
