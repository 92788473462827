import Vue from 'vue';
import axios, { CancelToken } from 'axios';
import Qs from 'qs';
import { fetchAdapter } from '@/utils/fetcher';
import errorHandler from './errors';
import { STATUSES, API_PREFIXES as API_PREFIX_CONST } from './constants';

let store;
let modificationRequestCount = 0;
const modificationMethods = ['put', 'post', 'delete', 'patch'];
const beforeunload = (e) => {
  if (modificationRequestCount) {
    // eslint-disable-next-line no-param-reassign
    e.returnValue = Vue.t('Do you want to leave this site? Changes you made may not be saved.');
  }
};
window.addEventListener('beforeunload', beforeunload);

const local = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // Should never be undefined, but tests mandate default
    twProjectsVer: window.appVersionId || '',
    'Teams-Enabled': 'true',
  },
  adapter: fetchAdapter,
});

const setupTimezone = (tz) => {
  local.defaults.headers.common['Time-Zone'] = tz;
};

const API_PREFIXES = {
  v3cf: '',
  v3v1: API_PREFIX_CONST.v1,
  v3v2: API_PREFIX_CONST.v2,
  ...API_PREFIX_CONST,
};

// For internal account switch the internal testing API prefixes to v3
const switchInternalTestingPrefixes = () => {
  ['v3cf', 'v3v1', 'v3v2'].forEach((prefix) => {
    API_PREFIXES[prefix] = API_PREFIX_CONST.v3;
  });
};

// Caching - initialData
local.interceptors.request.use((config) => {
  // Used for app usage analytics
  // eslint-disable-next-line no-param-reassign
  config.headers['Referer-Path'] = window.location.hash.replace(/^#?\/*/, '/tko/');

  if (config.method === 'get' && window.initialData && window.initialData.enabled) {
    let cacheKey = config.url;
    if (config.params) {
      cacheKey =
        cacheKey + (cacheKey.includes('?') ? '&' : '?') + Qs.stringify(config.params, { arrayFormat: 'brackets' });
    }
    if (cacheKey[0] === '/') {
      cacheKey = cacheKey.slice(1);
    }
    if (window.initialData[cacheKey]) {
      console.info('API CACHE FOUND for ', cacheKey);
      const data = window.initialData[cacheKey];
      // Overwrite adapter to return cache response directly
      // eslint-disable-next-line no-param-reassign
      config.adapter = (cfg) =>
        Promise.resolve({
          data,
          status: 200,
          statusText: 'OK',
          headers: {},
          config: cfg,
          request: {},
        });
    }
  }
  if (modificationMethods.includes(config.method)) {
    modificationRequestCount += 1;
  }
  return config;
});

local.interceptors.response.use(
  /**
   * Intercept action headers and dispatch them
   */
  (rs) => {
    if (modificationMethods.includes(rs.config.method)) {
      modificationRequestCount -= 1;
    }
    if (store && rs && rs.headers) {
      // Note that Axios converts all header names to lowercase
      if (rs.headers.outageerrormessage) {
        store.dispatch('notifications/banners/add', {
          id: 'outageerrormessage',
          data: rs.headers.outageerrormessage,
        });
      }
    }
    return rs;
  },
  /**
   * Intercept errors
   */
  (error) => {
    if (error.config && modificationMethods.includes(error.config.method)) {
      modificationRequestCount -= 1;
    }
    if (
      error.config &&
      !error.config.noErrorHandling &&
      // `@/api` loaders get the initial data from the cache
      // and then request data from the server to remain up to date.
      // We don't want to report errors every time there's a cache miss.
      !error.config.cache === 'only-if-cached'
    ) {
      if (error.response) {
        (errorHandler[error.response.status] || errorHandler.default)(error, store);
      } else {
        errorHandler.noResponse(error, store);
      }
    }
    return Promise.reject(error);
  },
);

/**
 * Singleton Service to proxy underlying Ajax API
 *
 * Abstraction based on supporting current impl (axios),
 * but also https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */
const ApiService = {
  registerStore(storeToRegister) {
    store = storeToRegister;

    store.when(
      (state, getters) => getters['account/isFullyReady'],
      () => store.getters['account/isTWInternal'] && switchInternalTestingPrefixes(),
    );

    store.when(
      (state, getters) => getters['user/isReady'],
      () =>
        store.state.user.localization.timezoneJavaRefCode &&
        setupTimezone(store.state.user.localization.timezoneJavaRefCode),
    );
  },
  aborter: () => {
    const newToken = CancelToken.source();
    return {
      abort: () => newToken.cancel(),
      token: newToken.token,
    };
  },
  isAborted: (thrown) => axios.isCancel(thrown),
  setSocketId: (id) =>
    modificationMethods.forEach((method) => {
      local.defaults.headers[method]['Socket-ID'] = id;
    }),
};

const normalizeAborter = (config) => (config.aborter ? { ...config, cancelToken: config.aborter.token } : config);

const addHeaders = (config, headers) => {
  const result = {
    ...config,
    headers: config.headers ? { ...headers, ...config.headers } : headers,
  };
  if (config.longRun) {
    result.headers['Long-Run'] = config.longRun;
  }
  return result;
};

['get', 'delete', 'head', 'options'].forEach((method) => {
  ApiService[method] = (url, config = {}) => local[method](url, normalizeAborter(config));
});
['post', 'put', 'patch'].forEach((method) => {
  ApiService[method] = (url, data, config = {}) => {
    const headers = { 'Content-Type': 'application/json' };
    return local[method](url, data, normalizeAborter(addHeaders(config, headers)));
  };
});
ApiService.form = (url, data, method, config = {}) => {
  const stringData = typeof data === 'string' ? data : Qs.stringify(data);
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  };
  return local[method](url, stringData, normalizeAborter(addHeaders(config, headers)));
};

export default ApiService;
export { STATUSES, API_PREFIXES, beforeunload, local as axiosInstance };
