/**
 * t is needed for i18n,
 * @param assignedTo Array of { id, firstName, lastName }
 * @param includesUser true if current user in assignees
 */
export const summary = (t) => (assignedTo, includesUser) => {
  if (!assignedTo || !assignedTo.length) {
    return t('Anyone');
  }

  const other = (len) => (len > 1 ? ` + ${len - 1} ${len > 2 ? t('others') : t('other')}` : '');

  const { label } = assignedTo[0];
  const assignee = includesUser && assignedTo.length > 1 ? t('You') : label;
  return assignee + other(assignedTo.length);
};

/**
 * take an array of objects and produce an array of IDs
 * @param {*} assignedTo
 */
export const convertAssignedTo = (assignedTo = []) =>
  assignedTo.map(({ id, companyId, teamId }) => id || (companyId && `c${companyId}`) || (teamId && `t${teamId}`));

const idNamePairs = (idsStr, namesStr, key = 'id') => {
  if (!idsStr.length) {
    return [];
  }
  const ids = idsStr.split(',').map(Number);
  const names = namesStr.split(',');
  return ids.map((id, index) => ({ [key]: id, label: names[index] }));
};

/**
 * Unify separate assignee fields under 1,
 * this function can be run safely over its output too
 * @param {*} task
 */
export const unifyAssignedTo = (record) => {
  // v1 format
  if (record['responsible-party-ids']) {
    return [
      ...idNamePairs(record['responsible-party-ids'], record['responsible-party-names'], 'id'),
      ...idNamePairs(record.teamIds, record.teamNames, 'teamId'),
      ...idNamePairs(record.companyIds, record.companyNames, 'companyId'),
    ];
  }
  // v2+ format
  const { assignedTo = [], assignedToTeams = [], assignedToCompanies = [] } = record;
  return [
    ...assignedTo.map((asg) => (asg.label ? asg : { id: asg.id, label: `${asg.firstName} ${asg.lastName}` })),
    ...assignedToTeams.map(({ teamId, teamName, teamLogo, teamLogoColor, teamLogoIcon }) => ({
      teamId,
      label: teamName,
      logo: teamLogo,
      color: teamLogoColor,
      icon: teamLogoIcon,
    })),
    ...assignedToCompanies.map(({ companyId, companyName, companyLogoURL }) => ({
      companyId,
      label: companyName,
      logo: companyLogoURL,
    })),
  ];
};
